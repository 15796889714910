import React from "react"

import NavBar from "../component/NavBar"
import MusingCard from "./musings/MusingCard"

import "./Musing.css"

class Musing extends React.Component {

    render() {
      return(
        <div className="Page">
            <NavBar showLogo={true} />
            <div id="Musing" className="Content Veiling">
                <div id="MusingCardsContainer">
                    <MusingCard title="Elmélkedés" author="Ismeretlen Szerző" path="../read/musing7.pdf" />
                    <MusingCard title="Zen leírás" author="Subosits Tamás" path="../read/musing6.pdf" />
                    <MusingCard title="Teisho 2021.11.07" author="Subosits Tamás" path="../read/musing5.pdf" />
                    <MusingCard title="Pünkösdhétfői levél" author="Marghescu Mária" path="../read/musing4.pdf" />
                    <MusingCard title="A négy fogadalom" author="Marghescu Mária" path="../read/musing3.pdf" />
                    <MusingCard title="A megöregedés is egy életfeladat!" author="Peter Terness" translator="Marghescu Mária, Szita Anna" path="../read/musing2.pdf" />
                    <MusingCard title="Kiút lehet-e a zen az egzisztenciális zsákutcából?" author="Peter Terness" translator="Szita Anna, Subosits Tamás"  path="../read/musing1.pdf" />
                </div>
            </div>
        </div>
      )
    }
  }
  
  export default Musing